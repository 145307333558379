import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

// Временный компонент, который нужен для определения какой использовать компонент
// для ссылки - `<Link/>` или `<a/>`. Дело в том, что некоторые внутренние страницы
// этого сайта ведут на страницы старого сайта, и должны быть `<a/>` вместо `<Link/>`
// Например, в шапке сайта, половина ссылок может вести на такие "псевдо-внутренние"
// страницы. После того как будут сделаны все страницы, компонент заменится на `<Link/>`
const AdaptiveLink = props =>
	props.useGatsbyLink ? (
		<Link
			className={props.className || ""}
			to={props.href}
			itemProp={props.itemprop || null}
		>
			{props.children}
		</Link>
	) : (
		<a
			className={props.className || ""}
			href={props.href}
			itemProp={props.itemprop || null}
		>
			{props.children}
		</a>
	)

AdaptiveLink.propTypes = {
	// Должна ли ссылка обрабатываться gatsby-link
	useGatsbyLink: PropTypes.bool,
	href: PropTypes.string.isRequired,
	className: PropTypes.string,
	itemprop: PropTypes.string, // https://schema.org/docs/gs.html#microdata_itemprop
	children: PropTypes.node,
}

export default AdaptiveLink
