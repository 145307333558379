import React from 'react'

import classNames from 'classnames'
import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/docs.module.scss'
import AdaptiveLink from '../../components/AdaptiveLink'
import { useLocalizationContext } from '../../localization/useLocalizationContext'
import { pagesLinks } from '../../pages-data/common/links'
import { externalLinks } from '../../pages-data/_V2/common/links'

export default function PagePrivacyPolicy() {
  const localizationContext = useLocalizationContext()

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}licenseAgreement/annex/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}licenseAgreement/annex/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}licenseAgreement/annex/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}licenseAgreement/annex/`,
  }
  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
    // },
    {
      name: 'description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: 'Лицензионный договор на право использования системы автоматизации Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  if (localizationContext.locale === 'ru-BY') {
    return (
      <DefaultLayout
        title="Приложение № 1"
        metaTags={metaTags}
        mainWrapperClassName={classNames(
				  pageStyles.pageWrapper,
				  pageStyles.pageGray,
				  styles.docs,
        )}
        linkCanonical="https://quickresto.by/licenseAgreement/annex/"
        footerClassName={pageStyles.pageSection}
      >
        <section
          className={classNames(styles.docs__container, pageStyles.pageSection)}
        >
          <div className={styles.docs__content}>
            <h1 className={styles.bigMb}>
              Условия приобретения лицензии на брендированное мобильное
              приложение
            </h1>
            <div>
              <p>
                Приложение № 1 к сублицензионному договору на право использования программы для ЭВМ
                «Система автоматизации предприятий общественного питания "Quick Resto"»
              </p>

              <p>
                ИП Дятлов К.В., именуемый в дальнейшем «Лицензиат», с одной стороны, а с другой
                стороны любое лицо, пользователь услуг сайта www.quickresto.by, именуемый в дальнейшем
                «Сублицензиат», принявшее (акцептовавшее) публичное предложение (оферту) Лицензиата,
                совершая нижеследующие действия, означающие безоговорочное присоединение в целом ко
                всем условиям настоящего Приложения № 1 к Сублицензионному договору:
              </p>

              <p>
                1. За дополнительное вознаграждение Лицензиат предоставляет Сублицензиату
                простую (неисключительную) лицензию (разрешение) на использование программного
                обеспечения для ЭВМ «Брендированное мобильное приложение» (далее – Брендированное
                мобильное приложение, Мобильное приложение), являющееся результатом интеллектуальной
                деятельности Лицензиара – общества с ограниченной ответственностью «Квик Ресто»,
                которое Лицензиат в соответствии с требованиями Сублицензиата адаптирует под его
                деятельность для использования Мобильного приложения клиентами Сублицензиата
                (потребителями его товаров, работ, услуг).
              </p>

              <p>
                2. Лицензиат гарантирует наличие у него необходимых имущественных прав на
                распространение Мобильного приложения, в том числе на заключение сублицензионных
                договоров и предоставление по ним неисключительных сублицензий на право использования
                Мобильного приложения конечным пользователям на территории Республики Беларусь на
                основании договора с правообладателем (Лицензиаром). Лицензиат гарантирует, что права на
                Мобильное приложение не заложены, не арестованы и не являются предметом судебного
                спора с третьими лицами.
              </p>

              <p>
                3. Лицензия на Мобильное приложение считается переданной Лицензиатом
                Сублицензиату с момента акцепта Сублицензиатом настоящего Приложения № 1, под
                которым признается осуществление совокупности следующих действий:
              </p>

              <p>
                3.1. уплата согласованного сторонами в счете-протоколе обеспечительного платежа
                Лицензиату за предоставление лицензии на Мобильное приложение, который
                устанавливается в размере вознаграждения Лицензиата за предоставленную лицензию на
                Мобильное приложение не менее, чем за 1 (один) месяц и с момента передачи лицензии
                засчитывается в счет оплаты вознаграждения за соответствующий период пользования
                лицензией на Мобильное приложение;
              </p>

              <p>
                3.2. предоставление Сублицензиатом Лицензиату информации в целях адаптации
                Лицензиатом Мобильного приложения под требования Сублицензиата. 4. В дальнейшем
                оплата вознаграждения за предоставление лицензии на Мобильное приложение
                осуществляется на основании согласованных сторонами счетов протоколов.
              </p>

              <p>
                5. Предоставляя Лицензиату информацию, указанную в п. 3.2. настоящего Приложения,
                Сублицензиат подтверждает право Лицензиата на её использование, в том числе право
                использования наименования, логотипов, товарных знаков, знаков обслуживания и иных
                средств индивидуализации Сублицензиата с целью адаптации Мобильного приложения для
                деятельности Сублицензиата и дальнейшего функционирования Мобильного приложения.
              </p>

              <p>
                6. После адаптации Мобильного приложения оно размещается Лицензиатом под его
                учетной записью в магазинах приложений App Store и Google Play и доступно к бесплатному
                скачиванию на мобильные устройства любыми лицами, как действующими, так и
                потенциальными клиентами Сублицензиата.
              </p>

              <p>
                7. Во всем ином, что не предусмотрено настоящим Приложением № 1, применяются
                положения Сублицензионного договора на право использования программы для ЭВМ
                «Система автоматизации предприятий общественного питания «"Quick Resto"», а также
                положения действующего законодательства Республики Беларусь.
              </p>

              <p>
                8. В случае противоречия между условиями настоящего Приложения № 1 и условиями
                указанного Сублицензионного договора в части предоставления лицензии на Мобильное
                приложение, применяются условия настоящего Приложения № 1.
              </p>

              <p>
                9. Краткое описание программы для ЭВМ «Брендированное мобильное приложение»,
                её возможностей и преимуществ содержится по следующей интернет ссылке:
                https://quickresto.ru/capability
              </p>
            </div>
          </div>
        </section>

        <CtaForm
          isFry={false}
          className={pageStyles.pageSection}
          isWhiteBackground
        />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout
      title="Приложение № 1"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.docs,
      )}
      linkCanonical="https://quickresto.ru/licenseAgreement/annex/"
      footerClassName={pageStyles.pageSection}
    >
      <section
        className={classNames(styles.docs__container, pageStyles.pageSection)}
      >
        <div className={styles.docs__content}>
          <h1 className={styles.bigMb}>
            Специальные условия Лицензии на Опциональную программу -
            Брендированное мобильное приложение «White label»
            входящую в пакет Опциональных программ для ЭВМ «Лояльность.360»
          </h1>

          <div>
            <p>
              Приложение № 1 к Лицензионному договору на право использования
              программы для ЭВМ «Система автоматизации предприятий
              общественного питания «Quick Resto»» и иных
              программных продуктов ООО «Квик Ресто»
            </p>

          </div>

          <div>
            <p />
            <p>1. Настоящее Приложение, как и Лицензионный договор, является офертой <br /> ООО&nbsp;<strong>&laquo;Квик
              Ресто&raquo;</strong>&nbsp;(ОГРН 5137746099046, ИНН&nbsp;7726734798, КПП 771401001, 125167 г. Москва,
              Ленинградский пр. 36, стр. 30, пом. 96), именуемого в дальнейшем Лицензиар, Пользователю, именуемому в
              дальнейшем Лицензиат</p>
            <p><br />2. Под офертой согласно настоящему Приложению № 1 подразумевается предложение Лицензиара
              адресованное, <u>имеющему лицензию на основную Программу Лицензиату</u>, приобрести на срок не превышающий
              срок лицензии основного Программного продукта, за дополнительное вознаграждение Пакет опциональных
              программ для ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в указанный пакет Опциональную
              программу <strong>Брендированное мобильное приложение &laquo;White label&raquo;</strong> на основании
              простой (неисключительной) лицензии на результат интеллектуальной деятельности ООО &laquo;Квик
              Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ &laquo;Брендированное мобильное
              приложение &laquo;Whitelable&raquo;&raquo; (далее &ndash; &laquo;Брендированное мобильное
              приложение&raquo;, или &laquo;Мобильное приложение&raquo;), которую Лицензиар в соответствии с
              требованиями Лицензиата визуально адаптирует под его деятельность и которая предназначается для
              использования клиентами Лицензиата (потребителями товаров, работ, услуг Лицензиата).</p>
            <p>Простая (неисключительная) лицензия на результат интеллектуальной деятельности ООО &laquo;Квик
              Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ &laquo;Брендированное мобильное
              приложение &laquo;Whitelable&raquo; реализуется исключительно в пакете опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; и исключительно пользователям имеющим Лицензию на основную программу и
              исключительно на срок не более чем срок лицензии на основной программный продукт (Программу).</p>
            <p>Акцепт на указанную в п.2 настоящего Приложения №1 оферту возможен при одновременном соблюдении 4
              условий:</p>
            <p>1) Лицензиат приобретающий Лицензию на Мобильное приложение в пакете Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; должен иметь Лицензию на основной программный продукт (Программу)</p>
            <p>2) Лицензиат приобретает Лицензию на Мобильное приложение в Пакете Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; со всеми входящими в указанный Пакет опциональными программами.</p>
            <p>3) Оплата вознаграждения за пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; по счету,
              выставленному Лицензиаром Лицензиату в соответствии с Тарифным планом, выбранным Лицензиатом</p>
            <p>4) Лицензиат приобретает Лицензию на Мобильное приложение в Пакете Опциональных
              программ &laquo;Лояльность.360&raquo; на срок не более чем срок действующей лицензии на основной
              программный продукт.</p>
            <p>При не соблюдении любого из вышеуказанных пунктов &ndash; оферта не считается акцептованной
              Лицензиатом</p>
            <p>2.1. Под адаптацией Мобильного приложения под деятельность Лицензиата подразумевается исключительно
              визуальное адаптирование, например: визуальное воспроизведение в Мобильном приложении наименования
              (фирменного наименования) Лицензиата, его товарных знаков и/или знаков обслуживания, логотипов, фирменных
              цветов, названия создаваемого мобильного приложения, краткое описание для магазинов, расширенное описание
              для магазинов, адрес сайта/страницу в соцсетях, email (для связи от гостей), политику конфиденциальности,
              ключевые слова/теги для поиска в магазинах, список стран где будет доступно приложение, полное юридическое
              наименование и адрес компании и иной необходимой информации о Лицензиате. Указанная адаптация не
              предусматривает изменение функционала (возможностей) Мобильного приложения, в связи с чем Мобильное
              приложение является универсальным программным продуктом и предоставляется пользователям (Лицензиатам) в
              том виде &laquo;как есть&raquo;, за исключением Визуальной адаптации, указанной в настоящем пункте
              Приложения № 1.</p>
            <p>Под конфигурацией понимается ряд необходимых действий Лицензиара /сотрудников Лицензиара по настройке
              мобильного приложения для его корректной работы с основной Программой Лицензиара и иными Опциональными
              Программами и последующей корректной работы после публикации в магазинах приложений App Store и Google
              Play</p>
            <p>Под публикацией понимается действия Лицензиара, после Адаптации Мобильного приложения под требования
              Лицензиата и его конфигурации и согласования с Лицензиатом результатов, по размещению (публикацией) под
              своей учетной записью Мобильного приложение, адаптированного под деятельность Лицензиата, в магазинах
              приложений App Store и Google Play.</p>
            <p>2.2. Функционал мобильного приложения входящего в пакет Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo;</p>
            <p>1 - авторизация гостя, доступ в личный кабинет с просмотром накопленных бонусов, qr-кода для авторизации,
              историей заказов;</p>
            <p>2 - отображение акций клиента (маркетинговое изображение, описание, период действия);</p>
            <p>3 - Просмотр меню (блюда, описания, цены, КБЖУ);</p>
            <p>4 - Информация о заведении Лицензиата (адрес заведения, отметка на карте, время работы и контакты);</p>
            <p>5 - Отправка пуш-уведомлений гостям</p>
            <p>6 - Возможность оформления доставки или самовывоза (Добавление блюд в корзину, выбор времени, оплата
              онлайн или при получении, оплата бонусами, применение промокодов)</p>
            <p>При этом для доступа к некоторым функциям Приложения (Бонусная программа, просмотр истории заказов,
              осуществление предзаказов и др.) необходимо обязательная авторизация пользователя посредством
              подтвержденного номера телефона. Подтверждения номера телефона осуществляется за счет средств Лицензиата
              через смс-сообщение, push-уведомление или иным способом (например, посредством мессенджера Телеграмм),
              который позволяют идентифицировать пользователю по его абонентскому номеру сотового телефона.</p>
            <p>3. Лицензиар гарантирует Лицензиату, что он является законным правообладателем программы для
              ЭВМ &laquo;Брендированное мобильное приложение &laquo;Whitelabel&raquo;.</p>
            <p>4. Адаптация, конфигурация и публикация Мобильного приложения под деятельность Лицензиата осуществляется
              Лицензиаром в <u>срок оговоренный с Лицензиатом</u>, после осуществления Лицензиатом оплаты лицензионного
              вознаграждения за использование пакета опциональных программ &laquo;Лояльность.360&raquo; и предоставления
              Лицензиатом необходимых для Адаптации материалов и информации.</p>
            <p><u>Срок оговоренный с Лицензиатом</u> на адаптацию, конфигурацию и публикацию может быть продлен в
              случае, если необходимые документы не были предоставлены в оговоренные сроки или по иным причинам
              независящим от Лицензиара.</p>
            <p>Срок действия Лицензии на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в частности
              входящую в указанный пакет Опциональную программу <strong>Брендированное мобильное приложение &laquo;White
                label&raquo;</strong> и срок доступа к Опциональной программе <strong>Брендированное мобильное
                приложение &laquo;White label&raquo; </strong>входящейПакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo;, определяются в соответствии с п. 2.2, 2.4, Разделом 2 и Разделом 4
              Лицензионного договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий
              общественного питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;.
            </p>
            <p>5. По итогам каждого периода использования прав на Пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в указанный ранее пакет Опциональную
              программу <strong>Брендированное мобильное приложение &laquo;White label&raquo;</strong> Лицензиар
              направляет на электронную почту, указанную Лицензиатом, акт исполнения обязательств по настоящему
              Договору. При отсутствии у Лицензиата в течение 3 (трех) календарных дней с момента направления ему
              данного акта и отсутствия мотивированных возражений, данный акт считается согласованным и подписанным
              Лицензиатом, а услуги принятыми без претензий к качеству и срокам.</p>
            <p>6. В дальнейшем продление Лицензии на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в
              частности входящую в указанный пакет Опциональную программу <strong>Брендированное мобильное
                приложение &laquo;White label&raquo;</strong> и оплата вознаграждения за предоставление Лицензии на
              Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в указанный пакет
              Опциональную программу <strong>Брендированное мобильное приложение &laquo;White label&raquo; </strong>осуществляется
              на условиях определяются в соответствии с Разделом 2 и Разделом 4 Лицензионного договора на право
              использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;</p>
            <p><br />7. Предоставляя Лицензиару материалы и информацию, указанные в п.4. настоящего Приложения № 1,
              Лицензиат подтверждает право Лицензиара на их использование, в том числе право использования наименования,
              логотипов, товарных знаков, знаков обслуживания и иных средств индивидуализации Лицензиата с целью
              адаптации Мобильного приложения для деятельности Лицензиата и дальнейшего функционирования Мобильного
              приложения и гарантирует Лицензиару что все предоставленные Лицензиатом средства индивидуализации,
              логотипы, товарные знаки и иное принадлежат Лицензиату.</p>
            <p><br />8. Мобильное приложение, адаптированное Лицензиаром под деятельность Лицензиата и размещенное
              (опубликованное) Лицензиаром под его учетной записью в магазинах приложений App Store и Google Play,
              доступно к бесплатному скачиванию на мобильные устройства любыми лицами, являющимися как клиентами
              Лицензиата, так и намеренными ими стать.</p>
            <p><br />9. Во всем ином, не предусмотренном настоящим Приложением № 1, применяются положения Лицензионного
              договора, а также положения действующего законодательства Российской Федерации.</p>
            <p>10. Лицензиат подтверждает, что:</p>
            <p>&middot;&nbsp;перед заключением настоящего Приложения № 1 в полном объеме ознакомлен с функционалом
              (возможностями) Мобильного приложения - Краткое описание программы для ЭВМ &laquo;Брендированное мобильное
              приложение &laquo;Whitelabel&raquo;&raquo; и её возможностей, а также преимуществ, содержится по следующей
              интернет ссылке:&nbsp;<a href="https://quickresto.ru/wl/">https://quickresto.ru/wl/.</a>;</p>
            <p>&middot; перед заключением настоящего Приложения № 1 в полном объеме ознакомлен с текстом Лицензионного
              договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo;&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;, в
              котором содержатся все основные условия предоставления лицензий на программные продукты ООО &laquo;Квик
              Ресто&raquo; и который содержится по следующей интернет ссылке: <u><a
                href="https://quickresto.ru/licenseAgreement/">https://quickresto.ru/licenseAgreement/</a></u>;</p>
            <p>- соглашается с тем, что:</p>
            <p>С 01.09.2024 г. новые Лицензиаты (новые клиенты) не имеют возможность приобрести лицензию на пакет
              Опциональных программ &laquo;Лояльность.360&raquo; без приобретения Программы.</p>
            <p>Действующие Лицензиаты, имеющие оплаченные/приобретенные Лицензии на опциональные программы на 01.09.2024
              г. (до введения пакета Опциональных программ &laquo;Лояльность.360&raquo;) пользуются лицензиями до
              момента окончания своих действующих лицензий на опциональные программы, после окончания срока таких
              лицензий возможность приобрести/продлить опциональные программы без покупки основной программы не
              допускается.</p>
            <p>С 01.09.2024 г. срок действия лицензии на пакет Опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; не может быть больше, чем срок действия лицензии на основную Программу,
              указанное правило не распространяется на уже действующие лицензии.</p>
            <p>- Уведомлен об отсутствии, в связи с изменением тарифной политики, возможности приобрести <strong>Брендированное
              мобильное приложение &laquo;White label&raquo; отдельно от </strong>опциональных программ из Пакета
              Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo;,</p>
            <p>- Предупрежден о том, что без приобретения Лицензии на основную Программу/в случае окончания лицензии на
              основную Программу, опциональные программы будут работать в ограниченном режиме, с ограниченным
              функционалом/нестабильно и Лицензиар не несет ответственности за такие действия, так как это не зависит от
              действий Лицензиара, а связано с техническими особенностями Программы и Опциональных программных
              продуктов</p>
            <p>- осознает, что Программа является основным продуктом Лицензиара, без которого использование любой
              опциональной программы из пакета Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в полном
              объеме будет функционально ограничено, в виду того, что опциональные программы являются вторичными
              (дополнительными) к Программе и внедряются в основную Программу, позволяя расширить ее функционал, а
              именно в панель управления Программой. В отсутствие приобретенной лицензии на основную Программу, основные
              разделы/модули в панели не будут доступны/будут работать нестабильно, что повлияет на работу Опциональных
              программ.</p>
            <p>&middot;&nbsp;осознаёт, что Мобильное приложение является универсальным программным продуктом и
              предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;, за следующим исключением:
              Мобильное приложение визуально адаптируется под деятельность Лицензиата, без изменения функционала
              Мобильного приложения, иные адаптации оцениваются человеко-часами и оплачиваются дополнительно;</p>
            <p>- осознаёт, что мобильное приложение обязано соответствовать правилам App Store и Google Play и
              соответствие Мобильного приложения правилам AppStore и GooglePlay и соблюдение таких правил возлагается на
              Лицензиата и полностью в ведении Лицензиата.</p>
            <p>- Лицензиар не несёт ответственность за удаление или ограничение доступа к Приложению по независящим от
              него причинам (Санкции, размещения Лицензиатам материалов нарушающих правила площадок, иные ограничения
              платформ).</p>
            <p>- Приложение после размещения в App Storе и Google Play, является общедоступным для скачивания любым
              пользователем и храниться на серверах, принадлежащих компаниям Apple и Google соответственно. При этом все
              пользовательские данные храниться на серверах Лицензиара и/или его партнёров (например Yandex.Cloud).</p>
            <p>- осознает, что при неуплате продления Лицензии на пакет опциональных программ для
              ЭВМ &laquo;Лояльность.360&raquo; куда входит <strong>Брендированное мобильное приложение &laquo;White
                label&raquo;</strong>, Лицензиар вправе отключить, а затем удалить Мобильное приложение Лицензиата из
              App Store и Google Play и отключить функционал настройки в облачном бэк-офисе Лицензиата;</p>
            <p>- заключает настоящее Приложение № 1, так как убедился в том, что Мобильное приложение в полной мере
              соответствует потребностям и ожиданиям Лицензиата.</p>
            <p>- Самостоятельного несет расходы на авторизацию пользователей мобильного приложения на основании п. 13.4
              Лицензионного соглашения.</p>
            <p>- Не несет расходы на хранение пользовательских данных Лицензиата, а также техническую поддержку учётных
              записей, на которых размещено Мобильное Приложение в App Store и Google Play, исключительно на период
              действия оплаченной Лицензии.</p>
          </div>
        </div>
      </section>

      <CtaForm
        isFry={false}
        className={pageStyles.pageSection}
        isWhiteBackground
      />
    </DefaultLayout>
  )
}
