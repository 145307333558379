export const externalLinks = {
	blog: "https://blog.quickresto.ru/",
	demoCloud: {
		href: "https://demo.quickresto.ru/",
		text: "Демо режим",
	},
	vk: "https://vk.com/quickresto",
	facebook: "https://www.facebook.com/quickresto.ru",
	instagram: "https://www.instagram.com/quickresto/",
	youtube: "https://www.youtube.com/channel/UCWvHmsk_UwaKepM6oHoJJgw/",
	oneC: "https://1c.ru/",
	skolkovo: "https://sk.ru/",
	appstoreTerminal: "https://apps.apple.com/ru/app/quick-resto/id1463928023",
	appstoreQRM: "https://apps.apple.com/ru/app/quick-resto-manager/id1496593019", //"https://apps.apple.com/ru/app/quick-resto-manager/id1097815928",
	appstoreAllApps:
		"https://apps.apple.com/ru/developer/quick-resto-ooo/id888261143",
	googlePlayClientsCrm:
		"https://play.google.com/store/apps/developer?id=Quick+Resto&hl=ru",
	googlePlayQRM:
		"https://play.google.com/store/apps/details?id=ru.quickresto.manager&hl=ru",
	googlePlayLine:
		"https://play.google.com/store/apps/details?id=ru.quickresto.qrqueue",
	quickrestoBY: {
		href: "https://quickresto.by/",
		text: "quickresto.by",
	},
	quickrestoKZ: {
		href: "https://quickresto.kz/",
		text: "quickresto.kz",
	},
	quickrestoDG: {
		href: "mailto:ahmedradjabov83@gmail.com",
		text: "ahmedradjabov83@gmail.com",
	},
	quickrestoMSK: {
		href: "https://kafeline.com/",
		text: "Kafeline.com",
	},
}

export const pagesLinks = {
	index: {
		text: "",
		href: "/",
		useGatsbyLink: true,
	},
	restoGuide: {
		href: "https://guide.quickresto.ru/",
		text: "Справочник ресторатора",
	},
	support: {
		enterprise: {
			href: "/support/rabota_s_bek_ofisom/enterprise/enterprises/",
		},
		venue: {
			href: "/support/rabota_s_bek_ofisom/enterprise/venues/",
		},
		cookingplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/cookingplaces/",
		},
		saleplace: {
			href: "/support/rabota_s_bek_ofisom/enterprise/saleplaces/",
		},
		warehouse: {
			href: "/support/rabota_s_bek_ofisom/enterprise/warehouses/",
		},
		dishes: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/dishes/",
		},
		polufabrikaty: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/polufabrikaty/",
		},
		modifikatory: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/modifikatory/",
		},
		tovary: {
			href: "/support/rabota_s_bek_ofisom/nomenklatura/tovary/",
		},
		versionnost_tekhnologicheskikh_kart: {
			href:
				"/support/rabota_s_bek_ofisom/nomenklatura/versionnost_tekhnologicheskikh_kart/",
		},
		prikhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/prikhodnye_nakladnye/",
		},
		raskhodnye_nakladnye: {
			href: "/support/rabota_s_bek_ofisom/sklad/raskhodnye_nakladnye/",
		},
		vnutrennie_peremeshcheniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/vnutrennie_peremeshcheniya/",
		},
		akty_spisaniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_spisaniya/",
		},
		akty_prigotovleniya: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_prigotovleniya/",
		},
		inventarizatsiya: {
			href: "/support/rabota_s_bek_ofisom/sklad/inventarizatsiya/",
		},
		akty_razbora: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/",
		},
		reworking: {
			href: "/support/rabota_s_bek_ofisom/sklad/akty_razbora/#reworking/",
		},
		tipy_oplat: {
			href: "/support/rabota_s_bek_ofisom/spravochniki/tipy_oplat/",
		},
		fiksirovannye_skidki: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#fixed_discounts",
		},
		bonusnye_programmy_: {
			href: "/support/rabota_s_bek_ofisom/crm/bonusnye_programmy_/",
		},
		offers: {
			href: "/support/rabota_s_bek_ofisom/crm/offers/",
		},
		nadbavki: {
			href: "/support/rabota_s_bek_ofisom/crm/nadbavki/",
		},
		skidki_po_raspisaniyu: {
			href: "/support/rabota_s_bek_ofisom/crm/discounts_/#shedule_discount",
		},
		defaultreports: {
			href: "/support/rabota_s_bek_ofisom/reports/defaultreports/",
		},
		genreports: {
			href: "/support/rabota_s_bek_ofisom/reports/genreports/",
		},
		text: "Поддержка",
		href: "/support/",
	},
	capability: {
		href: "/capability/", // дублируется для компонента Breadcrumbs
		text: "Возможности",
		index: {
			text: "Возможности",
			href: "/capability/",
			useGatsbyLink: true,
		},
		warehouseSection: {
			href: "/capability/#warehouse",
			id: "warehouse",
			text: "Складской учёт",
			useGatsbyLink: true,
		},
		modifiersSection: {
			href: "/capability/#modifiers",
			id: "modifiers",
			text: "Техкарты",
			useGatsbyLink: true,
		},
		analyticsSection: {
			href: "/capability/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		paymentTypesSection: {
			href: "/capability/#paymentTypes",
			id: "paymentTypes",
			text: "Типы оплат",
			useGatsbyLink: true,
		},
		loyaltySection: {
			href: "/capability/#loyalty",
			id: "loyalty",
			text: "CRM",
			useGatsbyLink: true,
		},
		supportSection: {
			href: "/capability/#support",
			id: "support",
			text: "Техническая поддержка 24/7",
			useGatsbyLink: true,
		},
		securitySection: {
			href: "/capability/#security",
			id: "security",
			text: "Безопасность",
			useGatsbyLink: true,
		},
		mobileAppsSection: {
			href: "/capability/#mobileApps",
			id: "mobileApps",
			text: "Приложения",
			useGatsbyLink: true,
		},
		integrationsSection: {
			href: "/capability/#integrations",
			id: "integrations",
			text: "Интеграции",
			useGatsbyLink: true,
		},
		shopCategoriesSection: {
			href: "/capability/#shopCategories",
			id: "shopCategories",
			text: "Оборудование",
			useGatsbyLink: true,
		},
	},
	shop: {
		index: {
			text: "Магазин",
			href: "/shop/",
		},
		quickRestoSets: {
			text: "Quick Resto Set",
			href: "/shop/quick_resto_set/",
		},
		qrBoxes: {
			text: "QR Box",
			href: "/shop/qr_box/",
		},
		iPadRacks: {
			text: "Стойки для iPad",
			href: "/shop/stoyki_dlya_ipad/",
		},
		fiscalRegistrars: {
			text: "Фискальные регистраторы",
			href: "/shop/fiskalnye_registratory/",
		},
		ticketPrinters: {
			text: "Тикет-принтеры",
			href: "/shop/tiket_printery/",
		},
		evotorTerminals: {
			text: "Смарт-терминалы Эвотор",
			href: "/shop/smart_terminal_evotor/",
		},
		routers: {
			text: "Роутеры",
			href: "/shop/routery/",
		},
		iPads: {
			text: "Apple iPad",
			href: "/shop/apple_ipad/",
		},
		moneyBoxes: {
			text: "Денежные ящики",
			href: "/shop/denezhnyy_yashchik/",
		},
		other: {
			text: "Прочее",
			href: "/shop/prochee/",
		},
		services: {
			text: "Услуги",
			href: "/shop/uslugi/",
		},
	},
	blog: {
		text: "Блог",
		href: "https://blog.quickresto.ru/",
	},
	lisenceAgreement: {
		text: "Лицензионное соглашение",
		href: "/licenseAgreement/",
		useGatsbyLink: true,
		annex: {
			href: "/annex/",
			useGatsbyLink: true,
			text: "Приложение №1",
		},
	},
	privacyPolicy: {
		text: "Политика конфиденциальности",
		href: "/privacy_policy/",
	},
	about: {
		text: "О компании",
		href: "/about_company/",
		useGatsbyLink: true,
	},
	contacts: {
		text: "Контакты",
		href: "/contacts/",
		useGatsbyLink: true,
	},
	partners: {
		signUp: {
			href: "#kak-stat-partnerom-quick-resto",
		},
		text: "Партнерам",
		href: "/partners/",
		useGatsbyLink: true,
	},
	deliveryAndPayment: {
		text: "Доставка и оплата",
		href: "/delivery-payment/",
	},
	registration: {
		text: "Начать бесплатно",
		href: "/registration/",
	},
	service: {
		text: "Сервис Quick Resto",
		href: "/service/",
		id: "serviceForm",
		useGatsbyLink: true,
	},
	order: {
		text: "Заказать приложение",
		href: "/WLApplicationOrder/",
		useGatsbyLink: true,
	},
	login: {
		text: "Войти",
		href: "/login/",
		useGatsbyLink: true,
	},
	newPassword: {
		href: "/newPassword/",
		useGatsbyLink: true,
	},
	api: {
		text: "API",
		href: "/api/",
	},
	personal: {
		text: "Личный кабинет",
		href: "/personal/",
	},
	price: {
		text: "Цены", // дублируется для компонента Breadcrumbs
		href: "/price/",
		index: {
			text: "Цены",
			href: "/price/",
			useGatsbyLink: true,
		},
		compare: {
			href: "/price/compare/",
			text: "Подробнее о тарифах и ценах",
			useGatsbyLink: true,
		},
	},
	businessTypes: {
		text: "Автоматизация",
		bar: {
			text: "Бар",
			href: "/automation/avtomatizaciya-bara/",
		},
		cafe: {
			text: "Кафе",
			href: "/automation/avtomatizaciya-kafe/",
			useGatsbyLink: true,
		},
		cookery: {
			text: "Кулинария",
			href: "/automation/avtomatizaciya-kulinarii/",
		},
		restaurant: {
			text: "Ресторан",
			href: "/automation/avtomatizaciya-restorana/",
		},
		refectory: {
			text: "Столовая",
			href: "/automation/avtomatizaciya-stolovoy/",
		},
		fastfood: {
			text: "Фастфуд",
			href: "/automation/avtomatizaciya-fastfood/",
		},
		franchise: {
			text: "Франшиза",
			href: "/franchise/",
		},
		foodtruck: {
			text: "Фудтрак",
			href: "/automation/avtomatizaciya-fudtraka/",
		},
		coffeehouse: {
			text: "Кофейня",
			href: "/automation/avtomatizaciya-kofejni/",
			useGatsbyLink: true,
		},
	},
	reviews: {
		madEspresso: {
			href: `${externalLinks.blog}mad_espresso_team_kak_gotovit_kofe/`,
		},
		vkusologia: {
			href: `${externalLinks.blog}fresh_bar/`,
		},
		bq: {
			href: `${externalLinks.blog}keys_myasobar_bk/`,
		},
		poke: {
			href: `${externalLinks.blog}poke_house_kak_zarabotat_na_neznakomoy_kukhne/`,
		},
		metallurgist: {
			href: `${externalLinks.blog}keys-khokkeynaya-arena-metallurg-mangitogorsk/`,
		},
		vkrutuyu: {
			href: `${externalLinks.blog}street-food/`,
		},
		zaymemsacofe: {
			href: `${externalLinks.blog}how-to-open-specialty-coffee-shop/`,
		},
		shegol: {
			href: `${externalLinks.blog}shchegol-coffee-shop-case/`,
		},
	},
	indexWL: {
		text: "Приложение для гостей",
		mobileText: "Назад",
		href: "/wl/",
		useGatsbyLink: true,
		// description: 'Приложение для гостей'
	},
	indexQRM: {
		href: "/qrm/",
		useGatsbyLink: true,
		description: "Приложение для руководителя",
	},
	indexKDS: {
		href: "/kds/",
		useGatsbyLink: true,
		description: "Кухонный экран",
	},
	terminal: {
		href: "/terminal/",
		useGatsbyLink: true,
		description: "Кассовый терминал",
	},
	bo: {
		text: "Бэк-офис",
		href: "/back-office/",
		useGatsbyLink: true,
		systemicApproach: {
			href: "/back-office/#systemicApproach",
			id: "systemicApproach",
			text: "CRM",
			useGatsbyLink: true,
		},
		techMapOrder: {
			href: "/back-office/#techMapOrder",
			id: "techMapOrder",
			text: "Меню",
			useGatsbyLink: true,
		},
		warehouseControl: {
			href: "/back-office/#warehouseControl",
			id: "warehouseControl",
			text: "Склад",
			useGatsbyLink: true,
		},
		analytics: {
			href: "/back-office/#analytics",
			id: "analytics",
			text: "Аналитика",
			useGatsbyLink: true,
		},
		defence: {
			href: "/back-office/#defence",
			id: "defence",
			useGatsbyLink: true,
		},
	},
	discounts: {
		text: "Акции",
		href: "/discounts/",
		index: {
			href: "/discounts/",
			text: "Акции",
			useGatsbyLink: true,
		},
		lite: {
			href: "/discounts/aktsiya_na_podpisku_tarif_lite/",
			useGatsbyLink: true,
			text: 'акция "Lite"',
		},
		pro: {
			href: "/discounts/stan_pro/",
			useGatsbyLink: true,
			text: 'акция "Pro"',
		},
		friend: {
			href: "/discounts/privedi_druga_2_0/",
			useGatsbyLink: true,
			text: 'акция "Приведи друга"',
		},
	},
	wlShop: {
		text: "Электронное меню",
		href: "/emenu/",
		index: {
			href: "/emenu/",
			text: "Электронное меню",
			useGatsbyLink: true,
		},
		presentation: {
			text: "Заказать",
			useGatsbyLink: true,
			href: "/emenu#order",
		},
	},
	line: {
		href: "/line/",
		useGatsbyLink: true,
		text: "Электронная очередь",
	},
}
